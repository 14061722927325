import { cloneDeep } from 'lodash'
import { set } from 'vue'

import { SfiAgreementStoreManagement } from '~/lib/entities/sfi/sfiAgreementStoreManagement'
import { ApiModel } from '~/plugins/api/model'

// sfiAgreementRepository shape:
// const dummyAgreementApiData = [
//   {
//     sfiAgreement: {
//       id: 'id1',
//       agreementReference: '12948-247737',
//       farmId: 'farmId1',
//       fundingProgrammeId: '35148d57-0c80-417a-9134-3c07e709d845',
//       agreementStartDate: '2021-01-01',
//       journalId: '886586b0-e84d-47eb-bfd5-df096ae571c2',
//       createdAt: '2021-01-01',
//       createdBy: 'createdBy1',
//       updatedAt: '2021-01-01',
//       updatedBy: 'updatedBy1',
//       deletedAt: null
//     },
//     sfiAgreementActions: [
//       {
//         sfiAgreementAction: {
//           id: 'id10',
//           fundingSchemeId: 'a591039a-4ae2-4d94-b67d-fb987b478f36',
//           actionStartDate: '2023-09-01',
//           actionEndDate: '2024-08-31',
//           journalId: '6ba9b9a1-184b-4228-ae00-9591be169a2d',
//           createdAt: '2021-01-01',
//           createdBy: 'createdBy1',
//           updatedAt: '2021-01-01',
//           updatedBy: 'updatedBy1',
//           deletedAt: null
//         },
//         sfiAgreementActionParcels: [
//           {
//             id: 'id21',
//             parcelUuid: '6f0ba676-0b2f-4641-80ab-f09bfaa08a8f',
//             isRotational: true,
//             journalId: '886586b0-e84d-47eb-bfd5-df096ae571c2',
//             createdAt: '2021-01-01',
//             createdBy: 'createdBy1',
//             updatedAt: '2021-01-01',
//             updatedBy: 'updatedBy1',
//             deletedAt: null,
//             rpaParcelReference: 'SU2484,2152',
//             areaHectaresIncludedInAction: 0.7
//           },
//           {
//             id: 'id22',
//             parcelUuid: 'b797d4f0-0572-426c-8d86-ef163ac2ba2b',
//             isRotational: false,
//             journalId: '886586b0-e84d-47eb-bfd5-df096ae571c2',
//             createdAt: '2021-01-01',
//             createdBy: 'createdBy1',
//             updatedAt: '2021-01-01',
//             updatedBy: 'updatedBy1',
//             deletedAt: null,
//             rpaParcelReference: '',
//             areaHectaresIncludedInAction: 1.0
//           }
//         ]
//       },
//       {
//         sfiAgreementAction: {
//           id: 'id11',
//           fundingSchemeId: 'c7b92516-eee5-4d4d-9645-1967bb09cac4',
//           actionStartDate: '2023-09-01',
//           actionEndDate: '2024-08-31',
//           journalId: '886586b0-e84d-47eb-bfd5-df096ae571c2',
//           createdAt: '2021-01-01',
//           createdBy: 'createdBy1',
//           updatedAt: '2021-01-01',
//           updatedBy: 'updatedBy1',
//           deletedAt: null
//         },
//         sfiAgreementActionParcels: [
//           {
//             id: 'id31',
//             parcelUuid: '6f0ba676-0b2f-4641-80ab-f09bfaa08a8f',
//             isRotational: false,
//             journalId: '886586b0-e84d-47eb-bfd5-df096ae571c2',
//             createdAt: '2021-01-01',
//             createdBy: 'createdBy1',
//             updatedAt: '2021-01-01',
//             updatedBy: 'updatedBy1',
//             deletedAt: null,
//             rpaParcelReference: 'SU2484,2152',
//             areaHectaresIncludedInAction: 1.23
//           },
//           {
//             id: 'id33',
//             parcelUuid: 'a49b1d0e-85e8-4b51-bdeb-da8757085c6b',
//             isRotational: true,
//             journalId: '886586b0-e84d-47eb-bfd5-df096ae571c2',
//             createdAt: '2021-01-01',
//             createdBy: 'createdBy1',
//             updatedAt: '2021-01-01',
//             updatedBy: 'updatedBy1',
//             deletedAt: null,
//             rpaParcelReference: 'SU2484,9876',
//             areaHectaresIncludedInAction: 0.5
//           }
//         ]
//       }
//     ]
//   }
// ]

export const state = () => {
  return {
    sfiAgreementApi: new ApiModel(),
    sfiAgreementRepository: {
      sfiAgreementPairs: []
    },

    sfiFundingSchemesLookupData: undefined
  }
}

export const getters = {
  getSfiAgreementApi: state => () => {
    return state.sfiAgreementApi
  },
  getSfiAgreementList: state => () => {
    return new SfiAgreementStoreManagement(state).getSfiAgreementList()
  },
  getSfiAgreementObjectFromId: state => sfiAgreementId => {
    console.log('getters: getSfiAgreementObjectFromId', sfiAgreementId)
    return new SfiAgreementStoreManagement(state).getSfiAgreementObjectFromId(sfiAgreementId)
  },
  getSfiAgreementObjectFromKey: state => sfiAgreementKey => {
    console.log('getters: getSfiAgreementObjectFromId', sfiAgreementKey)
    return new SfiAgreementStoreManagement(state).getSfiAgreementObjectFromKey(sfiAgreementKey)
  },

  /**
   * A storage place for funding schemes lookup
   * Required so that in offline mode we can use this
   * @param state
   * @param getters
   * @returns {*}
   */
  sfiFundingSchemesLookupData: (state, getters) => {
    return state.sfiFundingSchemesLookupData
  }
}

export const actions = {
  async loadSfiFundingSchemes({ commit, getters, rootGetters, state }) {
    const isOnline = rootGetters['device/isOnline']

    // If not online, then don't try to load anything into the store
    if (!isOnline) {
      return
    }

    const schemesApi = new ApiModel()

    await this.$api.funding(schemesApi).getSfiProgrammeAndSchemes()
    commit('setSfiFundingSchemes', { sfiFundingSchemeData: schemesApi.response.data })
  },

  async loadSfiAgreementsForFarm({ commit, rootGetters }, farmId) {
    const isOnline = rootGetters['device/isOnline']

    // If not online, then don't try to load anything into the store
    if (!isOnline) {
      return
    }

    await console.log('loadSfiAgreementsForFarm', farmId)
    const { response } = await this.$api
      .sfiAgreement(state.sfiAgreementApi)
      .useStorePath('sfiAgreement.sfiAgreementApi')
      .loadAgreementsForFarm(farmId)

    console.log('***SFI Agreement*** loadAgreementsForFarm response', response)

    // Set the _key values for the agreement tree to be the id values from the db
    // New rows will be assigned _key using nanoid
    const sfiAgreementApiData = response.data

    for (const sfiAgreement of sfiAgreementApiData) {
      sfiAgreement.sfiAgreement._key = sfiAgreement.sfiAgreement.id
      for (const sfiAgreementAction of sfiAgreement.sfiAgreementActions) {
        sfiAgreementAction.sfiAgreementAction._key = sfiAgreementAction.sfiAgreementAction.id
        for (const sfiAgreementActionParcel of sfiAgreementAction.sfiAgreementActionParcels) {
          sfiAgreementActionParcel._key = sfiAgreementActionParcel.id
        }
      }
    }
    commit('setSfiAgreementsForFarm', sfiAgreementApiData)
  },

  reSyncSfiAgreement({ commit }, sfiAgreementObject) {
    commit('reSyncSfiAgreement', sfiAgreementObject)
  }
}

export const mutations = {
  setSfiFundingSchemes(state, { sfiFundingSchemeData }) {
    set(state, 'sfiFundingSchemesLookupData', sfiFundingSchemeData)
  },

  setSfiAgreementsForFarm(state, sfiAgreementDataList) {
    const newAgreementPairs = []

    for (const sfiAgreementData of sfiAgreementDataList) {
      newAgreementPairs.push({
        id: sfiAgreementData.sfiAgreement._key,
        initialAgreement: cloneDeep(sfiAgreementData),
        currentAgreement: cloneDeep(sfiAgreementData)
      })
    }

    set(state, 'sfiAgreementRepository', { sfiAgreementPairs: newAgreementPairs })
  },

  reSyncSfiAgreement(state, sfiAgreementObject) {
    console.log('reSyncSfiAgreement', sfiAgreementObject)
    const sfiAgreementPair = state.sfiAgreementRepository.sfiAgreementPairs.find(
      sfiAgreementPair => sfiAgreementPair.id === sfiAgreementObject.sfiAgreement._key
    )

    if (sfiAgreementPair) {
      sfiAgreementPair.currentAgreement = cloneDeep(sfiAgreementObject)
      sfiAgreementPair.initialAgreement = cloneDeep(sfiAgreementObject)
    } else {
      // Not in the store, so add
      console.log('reSyncSfiAgreement: sfiAgreementPair not found')
      const newAgreementPair = {
        id: sfiAgreementObject.sfiAgreement._key,
        initialAgreement: cloneDeep(sfiAgreementObject),
        currentAgreement: cloneDeep(sfiAgreementObject)
      }

      set(state, 'sfiAgreementRepository', {
        sfiAgreementPairs: [...state.sfiAgreementRepository.sfiAgreementPairs, newAgreementPair]
      })
    }
  }
}
