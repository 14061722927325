import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a865be48 = () => interopDefault(import('../src/pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _7c943f80 = () => interopDefault(import('../src/pages/locale.vue' /* webpackChunkName: "pages/locale" */))
const _5932b15d = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5e455318 = () => interopDefault(import('../src/pages/mobile-menu.vue' /* webpackChunkName: "pages/mobile-menu" */))
const _04ba9ad6 = () => interopDefault(import('../src/pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _0455695c = () => interopDefault(import('../src/pages/platform/index.vue' /* webpackChunkName: "pages/platform/index" */))
const _734d5426 = () => interopDefault(import('../src/pages/register-complete.vue' /* webpackChunkName: "pages/register-complete" */))
const _2a6a92ec = () => interopDefault(import('../src/pages/setup/index.vue' /* webpackChunkName: "pages/setup/index" */))
const _38edca19 = () => interopDefault(import('../src/pages/admin/access-organisations/index.vue' /* webpackChunkName: "pages/admin/access-organisations/index" */))
const _5179f674 = () => interopDefault(import('../src/pages/admin/content/index.vue' /* webpackChunkName: "pages/admin/content/index" */))
const _09871eca = () => interopDefault(import('../src/pages/admin/farms/index.vue' /* webpackChunkName: "pages/admin/farms/index" */))
const _473bd493 = () => interopDefault(import('../src/pages/admin/farms/index/api-errors.vue' /* webpackChunkName: "pages/admin/farms/index/api-errors" */))
const _f40caf1a = () => interopDefault(import('../src/pages/admin/farms/index/list.vue' /* webpackChunkName: "pages/admin/farms/index/list" */))
const _0a408a17 = () => interopDefault(import('../src/pages/admin/farms/index/map.vue' /* webpackChunkName: "pages/admin/farms/index/map" */))
const _d823fa7a = () => interopDefault(import('../src/pages/admin/lab-results/index.vue' /* webpackChunkName: "pages/admin/lab-results/index" */))
const _61b9d6a1 = () => interopDefault(import('../src/pages/admin/lab-results/index/import.vue' /* webpackChunkName: "pages/admin/lab-results/index/import" */))
const _375b9d80 = () => interopDefault(import('../src/pages/admin/lab-results/index/imported.vue' /* webpackChunkName: "pages/admin/lab-results/index/imported" */))
const _0b87fee9 = () => interopDefault(import('../src/pages/admin/map/index.vue' /* webpackChunkName: "pages/admin/map/index" */))
const _65f9d5a6 = () => interopDefault(import('../src/pages/admin/organisations/index.vue' /* webpackChunkName: "pages/admin/organisations/index" */))
const _a6b19568 = () => interopDefault(import('../src/pages/admin/settings/index.vue' /* webpackChunkName: "pages/admin/settings/index" */))
const _dd9faa34 = () => interopDefault(import('../src/pages/admin/settings/index/profile.vue' /* webpackChunkName: "pages/admin/settings/index/profile" */))
const _7eaf0353 = () => interopDefault(import('../src/pages/admin/settings/index/security.vue' /* webpackChunkName: "pages/admin/settings/index/security" */))
const _6800d206 = () => interopDefault(import('../src/pages/admin/team/index.vue' /* webpackChunkName: "pages/admin/team/index" */))
const _38cb6c15 = () => interopDefault(import('../src/pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _461fcb78 = () => interopDefault(import('../src/pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _14293a68 = () => interopDefault(import('../src/pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _83e1c9ca = () => interopDefault(import('../src/pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _f6d64b0c = () => interopDefault(import('../src/pages/auth/native-callback.vue' /* webpackChunkName: "pages/auth/native-callback" */))
const _0eb956d8 = () => interopDefault(import('../src/pages/auth/organisations.vue' /* webpackChunkName: "pages/auth/organisations" */))
const _ef96f612 = () => interopDefault(import('../src/pages/auth/register/index.vue' /* webpackChunkName: "pages/auth/register/index" */))
const _625a0926 = () => interopDefault(import('../src/pages/platform/action-plan/index.vue' /* webpackChunkName: "pages/platform/action-plan/index" */))
const _e0d4ad84 = () => interopDefault(import('../src/pages/platform/action-plan/index/action-items/index.vue' /* webpackChunkName: "pages/platform/action-plan/index/action-items/index" */))
const _7e9eb50e = () => interopDefault(import('../src/pages/platform/action-plan/index/recommendation/index.vue' /* webpackChunkName: "pages/platform/action-plan/index/recommendation/index" */))
const _c93feada = () => interopDefault(import('../src/pages/platform/data/index.vue' /* webpackChunkName: "pages/platform/data/index" */))
const _8989a400 = () => interopDefault(import('../src/pages/platform/evidence/index.vue' /* webpackChunkName: "pages/platform/evidence/index" */))
const _5d93f26c = () => interopDefault(import('../src/pages/platform/funding/index.vue' /* webpackChunkName: "pages/platform/funding/index" */))
const _0ac4b7af = () => interopDefault(import('../src/pages/platform/learning-hub/index.vue' /* webpackChunkName: "pages/platform/learning-hub/index" */))
const _448ff12e = () => interopDefault(import('../src/pages/platform/map/index.vue' /* webpackChunkName: "pages/platform/map/index" */))
const _d0069fc6 = () => interopDefault(import('../src/pages/platform/report/index.vue' /* webpackChunkName: "pages/platform/report/index" */))
const _39a87ccc = () => interopDefault(import('../src/pages/platform/settings/index.vue' /* webpackChunkName: "pages/platform/settings/index" */))
const _2da03453 = () => interopDefault(import('../src/pages/platform/settings/index/business.vue' /* webpackChunkName: "pages/platform/settings/index/business" */))
const _ab9c1b34 = () => interopDefault(import('../src/pages/platform/settings/index/profile.vue' /* webpackChunkName: "pages/platform/settings/index/profile" */))
const _f433a85a = () => interopDefault(import('../src/pages/platform/settings/index/security.vue' /* webpackChunkName: "pages/platform/settings/index/security" */))
const _3f6aa9e9 = () => interopDefault(import('../src/pages/platform/sharing/index.vue' /* webpackChunkName: "pages/platform/sharing/index" */))
const _7a82e7ae = () => interopDefault(import('../src/pages/setup/farm.vue' /* webpackChunkName: "pages/setup/farm" */))
const _82f2b7c6 = () => interopDefault(import('../src/pages/setup/welcome/index.vue' /* webpackChunkName: "pages/setup/welcome/index" */))
const _710ff75b = () => interopDefault(import('../src/pages/setup/welcome/index/certifications.vue' /* webpackChunkName: "pages/setup/welcome/index/certifications" */))
const _64b875ca = () => interopDefault(import('../src/pages/setup/welcome/index/complete.vue' /* webpackChunkName: "pages/setup/welcome/index/complete" */))
const _767a6afd = () => interopDefault(import('../src/pages/setup/welcome/index/farm-enterprises.vue' /* webpackChunkName: "pages/setup/welcome/index/farm-enterprises" */))
const _7e2df4ce = () => interopDefault(import('../src/pages/setup/welcome/index/goals.vue' /* webpackChunkName: "pages/setup/welcome/index/goals" */))
const _14ecd6c9 = () => interopDefault(import('../src/pages/setup/welcome/index/relationships.vue' /* webpackChunkName: "pages/setup/welcome/index/relationships" */))
const _06d4ec80 = () => interopDefault(import('../src/pages/setup/welcome/index/services.vue' /* webpackChunkName: "pages/setup/welcome/index/services" */))
const _3196d63c = () => interopDefault(import('../src/pages/admin/content/articles/index.vue' /* webpackChunkName: "pages/admin/content/articles/index" */))
const _3aae9834 = () => interopDefault(import('../src/pages/admin/content/funding/index.vue' /* webpackChunkName: "pages/admin/content/funding/index" */))
const _d8617b14 = () => interopDefault(import('../src/pages/admin/content/quick-wins/index.vue' /* webpackChunkName: "pages/admin/content/quick-wins/index" */))
const _70d14cb1 = () => interopDefault(import('../src/pages/admin/organisations/clone.vue' /* webpackChunkName: "pages/admin/organisations/clone" */))
const _ece7a45e = () => interopDefault(import('../src/pages/partner/farms/new.vue' /* webpackChunkName: "pages/partner/farms/new" */))
const _d9d36308 = () => interopDefault(import('../src/pages/platform/data/carbon.vue' /* webpackChunkName: "pages/platform/data/carbon" */))
const _31d3c109 = () => interopDefault(import('../src/pages/platform/data/food-production.vue' /* webpackChunkName: "pages/platform/data/food-production" */))
const _06350f8e = () => interopDefault(import('../src/pages/platform/data/sampling/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index" */))
const _21576cbf = () => interopDefault(import('../src/pages/platform/data/sampling/index/overview.vue' /* webpackChunkName: "pages/platform/data/sampling/index/overview" */))
const _7ce9df85 = () => interopDefault(import('../src/pages/platform/data/sampling/index/soil-lab.vue' /* webpackChunkName: "pages/platform/data/sampling/index/soil-lab" */))
const _314dbc7e = () => interopDefault(import('../src/pages/platform/data/sampling/index/water.vue' /* webpackChunkName: "pages/platform/data/sampling/index/water" */))
const _6f7722d0 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/index" */))
const _ccc2a54a = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/birds/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/birds/index" */))
const _50508802 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/hedgerows/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/hedgerows/index" */))
const _630a195d = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/plants/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/plants/index" */))
const _49ea6102 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/birds/_planItemId.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/birds/_planItemId" */))
const _453f5323 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/hedgerows/_planItemId.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/hedgerows/_planItemId" */))
const _3301b201 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/plants/_planItemId.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/plants/_planItemId" */))
const _7b4b4c3c = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/soils/_planItemId/index.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/soils/_planItemId/index" */))
const _1469b2bc = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/soils/_planItemId/earthworms.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/soils/_planItemId/earthworms" */))
const _7c3b7bfe = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/soils/_planItemId/ph.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/soils/_planItemId/ph" */))
const _bd561756 = () => interopDefault(import('../src/pages/platform/data/sampling/index/_parcelId/soils/_planItemId/vess.vue' /* webpackChunkName: "pages/platform/data/sampling/index/_parcelId/soils/_planItemId/vess" */))
const _0a96a078 = () => interopDefault(import('../src/pages/platform/data/soil-cover.vue' /* webpackChunkName: "pages/platform/data/soil-cover" */))
const _cc900146 = () => interopDefault(import('../src/pages/platform/data/surveys/index.vue' /* webpackChunkName: "pages/platform/data/surveys/index" */))
const _82840750 = () => interopDefault(import('../src/pages/platform/data/water.vue' /* webpackChunkName: "pages/platform/data/water" */))
const _01e762e3 = () => interopDefault(import('../src/pages/platform/evidence/collection/index.vue' /* webpackChunkName: "pages/platform/evidence/collection/index" */))
const _64cd5b23 = () => interopDefault(import('../src/pages/platform/funding/funding-tool/index.vue' /* webpackChunkName: "pages/platform/funding/funding-tool/index" */))
const _7a12082c = () => interopDefault(import('../src/pages/platform/funding/funding-tool/index/practices.vue' /* webpackChunkName: "pages/platform/funding/funding-tool/index/practices" */))
const _2431d5fc = () => interopDefault(import('../src/pages/platform/funding/funding-tool/index/schemes.vue' /* webpackChunkName: "pages/platform/funding/funding-tool/index/schemes" */))
const _3730a0bc = () => interopDefault(import('../src/pages/platform/funding/natural-capital.vue' /* webpackChunkName: "pages/platform/funding/natural-capital" */))
const _083d6b38 = () => interopDefault(import('../src/pages/platform/funding/price-premiums.vue' /* webpackChunkName: "pages/platform/funding/price-premiums" */))
const _631eeffe = () => interopDefault(import('../src/pages/platform/funding/subsidies.vue' /* webpackChunkName: "pages/platform/funding/subsidies" */))
const _3d4df22a = () => interopDefault(import('../src/pages/platform/learning-hub/advice.vue' /* webpackChunkName: "pages/platform/learning-hub/advice" */))
const _6283ff3d = () => interopDefault(import('../src/pages/platform/learning-hub/case-studies.vue' /* webpackChunkName: "pages/platform/learning-hub/case-studies" */))
const _f47c289c = () => interopDefault(import('../src/pages/platform/learning-hub/tutorials.vue' /* webpackChunkName: "pages/platform/learning-hub/tutorials" */))
const _8d7f6166 = () => interopDefault(import('../src/pages/platform/report/archive.vue' /* webpackChunkName: "pages/platform/report/archive" */))
const _96d52276 = () => interopDefault(import('../src/pages/platform/report/v3/index.vue' /* webpackChunkName: "pages/platform/report/v3/index" */))
const _b059e6ec = () => interopDefault(import('../src/pages/admin/content/articles/new.vue' /* webpackChunkName: "pages/admin/content/articles/new" */))
const _cab7985e = () => interopDefault(import('../src/pages/admin/content/funding/bodies/index.vue' /* webpackChunkName: "pages/admin/content/funding/bodies/index" */))
const _c31bff30 = () => interopDefault(import('../src/pages/admin/content/funding/land-types/index.vue' /* webpackChunkName: "pages/admin/content/funding/land-types/index" */))
const _0a9dd14c = () => interopDefault(import('../src/pages/admin/content/funding/old/index.vue' /* webpackChunkName: "pages/admin/content/funding/old/index" */))
const _f78b1e06 = () => interopDefault(import('../src/pages/admin/content/funding/practices/index.vue' /* webpackChunkName: "pages/admin/content/funding/practices/index" */))
const _5af2b728 = () => interopDefault(import('../src/pages/admin/content/funding/programmes/index.vue' /* webpackChunkName: "pages/admin/content/funding/programmes/index" */))
const _76dc80d3 = () => interopDefault(import('../src/pages/admin/content/funding/schemes/index.vue' /* webpackChunkName: "pages/admin/content/funding/schemes/index" */))
const _1fcd3f44 = () => interopDefault(import('../src/pages/admin/content/quick-wins/new.vue' /* webpackChunkName: "pages/admin/content/quick-wins/new" */))
const _dcc84980 = () => interopDefault(import('../src/pages/platform/data/surveys/animal-welfare.vue' /* webpackChunkName: "pages/platform/data/surveys/animal-welfare" */))
const _3d5929d4 = () => interopDefault(import('../src/pages/platform/data/surveys/biodiversity.vue' /* webpackChunkName: "pages/platform/data/surveys/biodiversity" */))
const _b873f774 = () => interopDefault(import('../src/pages/platform/data/surveys/farm-practices.vue' /* webpackChunkName: "pages/platform/data/surveys/farm-practices" */))
const _042c0cf2 = () => interopDefault(import('../src/pages/platform/data/surveys/social.vue' /* webpackChunkName: "pages/platform/data/surveys/social" */))
const _85d41dbc = () => interopDefault(import('../src/pages/platform/data/surveys/water.vue' /* webpackChunkName: "pages/platform/data/surveys/water" */))
const _3a5b9941 = () => interopDefault(import('../src/pages/platform/evidence/sfi/management/index.vue' /* webpackChunkName: "pages/platform/evidence/sfi/management/index" */))
const _0386723c = () => interopDefault(import('../src/pages/platform/report/v2/animal-welfare/index.vue' /* webpackChunkName: "pages/platform/report/v2/animal-welfare/index" */))
const _563413d2 = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/index.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/index" */))
const _5fdff524 = () => interopDefault(import('../src/pages/platform/report/v2/carbon/index.vue' /* webpackChunkName: "pages/platform/report/v2/carbon/index" */))
const _f178c124 = () => interopDefault(import('../src/pages/platform/report/v2/social/index.vue' /* webpackChunkName: "pages/platform/report/v2/social/index" */))
const _1e076fee = () => interopDefault(import('../src/pages/platform/report/v2/soils/index.vue' /* webpackChunkName: "pages/platform/report/v2/soils/index" */))
const _7329f98c = () => interopDefault(import('../src/pages/platform/report/v2/water/index.vue' /* webpackChunkName: "pages/platform/report/v2/water/index" */))
const _055fc55b = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/index.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/index" */))
const _5235a2b1 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/index.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/index" */))
const _d6bc30e6 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/index.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/index" */))
const _4bd5818d = () => interopDefault(import('../src/pages/platform/report/v3/social/index.vue' /* webpackChunkName: "pages/platform/report/v3/social/index" */))
const _41a6260a = () => interopDefault(import('../src/pages/platform/report/v3/soils/index.vue' /* webpackChunkName: "pages/platform/report/v3/soils/index" */))
const _785850e6 = () => interopDefault(import('../src/pages/platform/report/v3/water/index.vue' /* webpackChunkName: "pages/platform/report/v3/water/index" */))
const _ab566942 = () => interopDefault(import('../src/pages/admin/content/funding/bodies/new.vue' /* webpackChunkName: "pages/admin/content/funding/bodies/new" */))
const _238ad1b6 = () => interopDefault(import('../src/pages/admin/content/funding/land-types/new.vue' /* webpackChunkName: "pages/admin/content/funding/land-types/new" */))
const _417d8b9a = () => interopDefault(import('../src/pages/admin/content/funding/old/new.vue' /* webpackChunkName: "pages/admin/content/funding/old/new" */))
const _a71f38ea = () => interopDefault(import('../src/pages/admin/content/funding/practices/new.vue' /* webpackChunkName: "pages/admin/content/funding/practices/new" */))
const _db6b8f14 = () => interopDefault(import('../src/pages/admin/content/funding/programmes/new.vue' /* webpackChunkName: "pages/admin/content/funding/programmes/new" */))
const _7ae070e1 = () => interopDefault(import('../src/pages/admin/content/funding/schemes/new.vue' /* webpackChunkName: "pages/admin/content/funding/schemes/new" */))
const _79f3f475 = () => interopDefault(import('../src/pages/platform/report/v2/animal-welfare/antibiotics.vue' /* webpackChunkName: "pages/platform/report/v2/animal-welfare/antibiotics" */))
const _50ae7732 = () => interopDefault(import('../src/pages/platform/report/v2/animal-welfare/outcomes.vue' /* webpackChunkName: "pages/platform/report/v2/animal-welfare/outcomes" */))
const _238b19da = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/birds.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/birds" */))
const _1108a27e = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/hedgerows.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/hedgerows" */))
const _d88a92a8 = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/landcover.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/landcover" */))
const _d1d724d0 = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/plants.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/plants" */))
const _6e2f6ad0 = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/practices.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/practices" */))
const _83c26d68 = () => interopDefault(import('../src/pages/platform/report/v2/biodiversity/woodland-connectivity.vue' /* webpackChunkName: "pages/platform/report/v2/biodiversity/woodland-connectivity" */))
const _45aaf510 = () => interopDefault(import('../src/pages/platform/report/v2/carbon/balance.vue' /* webpackChunkName: "pages/platform/report/v2/carbon/balance" */))
const _b501ad30 = () => interopDefault(import('../src/pages/platform/report/v2/carbon/emissions.vue' /* webpackChunkName: "pages/platform/report/v2/carbon/emissions" */))
const _4a3c0c9c = () => interopDefault(import('../src/pages/platform/report/v2/carbon/stored-in-soils.vue' /* webpackChunkName: "pages/platform/report/v2/carbon/stored-in-soils" */))
const _5d9ae320 = () => interopDefault(import('../src/pages/platform/report/v2/carbon/stored-in-vegetation.vue' /* webpackChunkName: "pages/platform/report/v2/carbon/stored-in-vegetation" */))
const _2e4c6dbc = () => interopDefault(import('../src/pages/platform/report/v2/social/land-access.vue' /* webpackChunkName: "pages/platform/report/v2/social/land-access" */))
const _34f404c6 = () => interopDefault(import('../src/pages/platform/report/v2/soils/bulk-density.vue' /* webpackChunkName: "pages/platform/report/v2/soils/bulk-density" */))
const _5aef0f0f = () => interopDefault(import('../src/pages/platform/report/v2/soils/earthworms.vue' /* webpackChunkName: "pages/platform/report/v2/soils/earthworms" */))
const _a140495e = () => interopDefault(import('../src/pages/platform/report/v2/soils/ph.vue' /* webpackChunkName: "pages/platform/report/v2/soils/ph" */))
const _2ad17df0 = () => interopDefault(import('../src/pages/platform/report/v2/soils/som.vue' /* webpackChunkName: "pages/platform/report/v2/soils/som" */))
const _c6426a30 = () => interopDefault(import('../src/pages/platform/report/v2/soils/vess.vue' /* webpackChunkName: "pages/platform/report/v2/soils/vess" */))
const _65261182 = () => interopDefault(import('../src/pages/platform/report/v2/water/nitrogen-balance.vue' /* webpackChunkName: "pages/platform/report/v2/water/nitrogen-balance" */))
const _4181fede = () => interopDefault(import('../src/pages/platform/report/v2/water/surface-runoff-avoidance.vue' /* webpackChunkName: "pages/platform/report/v2/water/surface-runoff-avoidance" */))
const _5d1f624a = () => interopDefault(import('../src/pages/platform/report/v2/water/usage.vue' /* webpackChunkName: "pages/platform/report/v2/water/usage" */))
const _1784478d = () => interopDefault(import('../src/pages/platform/report/v2/water/water-samples.vue' /* webpackChunkName: "pages/platform/report/v2/water/water-samples" */))
const _02243454 = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/antibiotics.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/antibiotics" */))
const _ac1ce668 = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/welfare-beef.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/welfare-beef" */))
const _095779db = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/welfare-dairy.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/welfare-dairy" */))
const _2ffbd0cd = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/welfare-pigs.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/welfare-pigs" */))
const _579fd72d = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/welfare-poultry.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/welfare-poultry" */))
const _b389173a = () => interopDefault(import('../src/pages/platform/report/v3/animal-welfare/welfare-sheep.vue' /* webpackChunkName: "pages/platform/report/v3/animal-welfare/welfare-sheep" */))
const _2aea6545 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/bird-species-abundance.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/bird-species-abundance" */))
const _196b5aa8 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/connectivity.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/connectivity" */))
const _314d68b4 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/crop-and-livestock-diversity.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/crop-and-livestock-diversity" */))
const _20756b37 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/flora.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/flora" */))
const _2fac865a = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/habitat-management.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/habitat-management" */))
const _87199928 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/hedgerow-structure.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/hedgerow-structure" */))
const _1049e843 = () => interopDefault(import('../src/pages/platform/report/v3/biodiversity/space-for-nature.vue' /* webpackChunkName: "pages/platform/report/v3/biodiversity/space-for-nature" */))
const _76674852 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/balance.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/balance" */))
const _2c04e407 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/emissions.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/emissions" */))
const _840dc5bc = () => interopDefault(import('../src/pages/platform/report/v3/carbon/hedgerow-flows.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/hedgerow-flows" */))
const _41de0e60 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/hedgerow-stocks.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/hedgerow-stocks" */))
const _7a19f60d = () => interopDefault(import('../src/pages/platform/report/v3/carbon/outside-woodland-flows.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/outside-woodland-flows" */))
const _b1e98576 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/outside-woodland-stocks.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/outside-woodland-stocks" */))
const _0f456be8 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/soil-flows.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/soil-flows" */))
const _1d9b2db4 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/soil-stocks.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/soil-stocks" */))
const _4e8c7de5 = () => interopDefault(import('../src/pages/platform/report/v3/carbon/woodland-flows.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/woodland-flows" */))
const _60e9b06d = () => interopDefault(import('../src/pages/platform/report/v3/carbon/woodland-stocks.vue' /* webpackChunkName: "pages/platform/report/v3/carbon/woodland-stocks" */))
const _552ddba4 = () => interopDefault(import('../src/pages/platform/report/v3/social/community.vue' /* webpackChunkName: "pages/platform/report/v3/social/community" */))
const _5acce9c2 = () => interopDefault(import('../src/pages/platform/report/v3/social/food-production-energy.vue' /* webpackChunkName: "pages/platform/report/v3/social/food-production-energy" */))
const _36494ffe = () => interopDefault(import('../src/pages/platform/report/v3/social/land-access.vue' /* webpackChunkName: "pages/platform/report/v3/social/land-access" */))
const _30f593a5 = () => interopDefault(import('../src/pages/platform/report/v3/soils/bulk-density.vue' /* webpackChunkName: "pages/platform/report/v3/soils/bulk-density" */))
const _e2e8fabe = () => interopDefault(import('../src/pages/platform/report/v3/soils/cn-ratio.vue' /* webpackChunkName: "pages/platform/report/v3/soils/cn-ratio" */))
const _3d166ca4 = () => interopDefault(import('../src/pages/platform/report/v3/soils/earthworms.vue' /* webpackChunkName: "pages/platform/report/v3/soils/earthworms" */))
const _dd84b620 = () => interopDefault(import('../src/pages/platform/report/v3/soils/ph.vue' /* webpackChunkName: "pages/platform/report/v3/soils/ph" */))
const _3dfa1761 = () => interopDefault(import('../src/pages/platform/report/v3/soils/soil-cover.vue' /* webpackChunkName: "pages/platform/report/v3/soils/soil-cover" */))
const _4472ab49 = () => interopDefault(import('../src/pages/platform/report/v3/soils/som.vue' /* webpackChunkName: "pages/platform/report/v3/soils/som" */))
const _7e70a8c7 = () => interopDefault(import('../src/pages/platform/report/v3/soils/vess.vue' /* webpackChunkName: "pages/platform/report/v3/soils/vess" */))
const _6011116b = () => interopDefault(import('../src/pages/platform/report/v3/water/groundwater.vue' /* webpackChunkName: "pages/platform/report/v3/water/groundwater" */))
const _838402c4 = () => interopDefault(import('../src/pages/platform/report/v3/water/nitrogen-balance.vue' /* webpackChunkName: "pages/platform/report/v3/water/nitrogen-balance" */))
const _19d26608 = () => interopDefault(import('../src/pages/platform/report/v3/water/phosphate-balance.vue' /* webpackChunkName: "pages/platform/report/v3/water/phosphate-balance" */))
const _599ff9e5 = () => interopDefault(import('../src/pages/platform/report/v3/water/potash-balance.vue' /* webpackChunkName: "pages/platform/report/v3/water/potash-balance" */))
const _03f7d175 = () => interopDefault(import('../src/pages/platform/report/v3/water/resource-availability.vue' /* webpackChunkName: "pages/platform/report/v3/water/resource-availability" */))
const _25f44be7 = () => interopDefault(import('../src/pages/platform/report/v3/water/runoff-management.vue' /* webpackChunkName: "pages/platform/report/v3/water/runoff-management" */))
const _246d9014 = () => interopDefault(import('../src/pages/platform/report/v3/water/storage.vue' /* webpackChunkName: "pages/platform/report/v3/water/storage" */))
const _3e35fd66 = () => interopDefault(import('../src/pages/platform/report/v3/water/water-usage.vue' /* webpackChunkName: "pages/platform/report/v3/water/water-usage" */))
const _9f7385c8 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _06599cd6 = () => interopDefault(import('../src/pages/admin/content/articles/view/_id.vue' /* webpackChunkName: "pages/admin/content/articles/view/_id" */))
const _d2c2130e = () => interopDefault(import('../src/pages/admin/content/funding/bodies/_id.vue' /* webpackChunkName: "pages/admin/content/funding/bodies/_id" */))
const _0fd4fcd0 = () => interopDefault(import('../src/pages/admin/content/funding/land-types/_id.vue' /* webpackChunkName: "pages/admin/content/funding/land-types/_id" */))
const _2dc7b6b4 = () => interopDefault(import('../src/pages/admin/content/funding/old/_id.vue' /* webpackChunkName: "pages/admin/content/funding/old/_id" */))
const _ce8ae2b6 = () => interopDefault(import('../src/pages/admin/content/funding/practices/_id.vue' /* webpackChunkName: "pages/admin/content/funding/practices/_id" */))
const _7e946390 = () => interopDefault(import('../src/pages/admin/content/funding/programmes/_id.vue' /* webpackChunkName: "pages/admin/content/funding/programmes/_id" */))
const _672a9bfb = () => interopDefault(import('../src/pages/admin/content/funding/schemes/_id.vue' /* webpackChunkName: "pages/admin/content/funding/schemes/_id" */))
const _0cdaf294 = () => interopDefault(import('../src/pages/platform/funding/funding-tool/practices/_id.vue' /* webpackChunkName: "pages/platform/funding/funding-tool/practices/_id" */))
const _d7c590b8 = () => interopDefault(import('../src/pages/admin/content/articles/_id.vue' /* webpackChunkName: "pages/admin/content/articles/_id" */))
const _0c176a5e = () => interopDefault(import('../src/pages/admin/content/quick-wins/_id.vue' /* webpackChunkName: "pages/admin/content/quick-wins/_id" */))
const _852d027e = () => interopDefault(import('../src/pages/admin/access-organisations/_id.vue' /* webpackChunkName: "pages/admin/access-organisations/_id" */))
const _65a5ece4 = () => interopDefault(import('../src/pages/admin/organisations/_id.vue' /* webpackChunkName: "pages/admin/organisations/_id" */))
const _214fd5a0 = () => interopDefault(import('../src/pages/admin/organisations/_id/activity.vue' /* webpackChunkName: "pages/admin/organisations/_id/activity" */))
const _66658b0a = () => interopDefault(import('../src/pages/admin/organisations/_id/overview.vue' /* webpackChunkName: "pages/admin/organisations/_id/overview" */))
const _406d87c7 = () => interopDefault(import('../src/pages/admin/organisations/_id/users.vue' /* webpackChunkName: "pages/admin/organisations/_id/users" */))
const _6a012086 = () => interopDefault(import('../src/pages/admin/users/_id.vue' /* webpackChunkName: "pages/admin/users/_id" */))
const _a768175e = () => interopDefault(import('../src/pages/admin/users/_id/activity.vue' /* webpackChunkName: "pages/admin/users/_id/activity" */))
const _49216f96 = () => interopDefault(import('../src/pages/admin/users/_id/notifications.vue' /* webpackChunkName: "pages/admin/users/_id/notifications" */))
const _1d3cac8a = () => interopDefault(import('../src/pages/admin/users/_id/overview.vue' /* webpackChunkName: "pages/admin/users/_id/overview" */))
const _06d3bef2 = () => interopDefault(import('../src/pages/auth/invited/_verificationCode.vue' /* webpackChunkName: "pages/auth/invited/_verificationCode" */))
const _ee8550b6 = () => interopDefault(import('../src/pages/auth/reset-password/_verificationCode/index.vue' /* webpackChunkName: "pages/auth/reset-password/_verificationCode/index" */))
const _bf024edc = () => interopDefault(import('../src/pages/platform/learning-hub/_slug/_id.vue' /* webpackChunkName: "pages/platform/learning-hub/_slug/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _a865be48,
    name: "admin"
  }, {
    path: "/locale",
    component: _7c943f80,
    name: "locale"
  }, {
    path: "/maintenance",
    component: _5932b15d,
    name: "maintenance"
  }, {
    path: "/mobile-menu",
    component: _5e455318,
    name: "mobile-menu"
  }, {
    path: "/partner",
    component: _04ba9ad6,
    name: "partner"
  }, {
    path: "/platform",
    component: _0455695c,
    name: "platform"
  }, {
    path: "/register-complete",
    component: _734d5426,
    name: "register-complete"
  }, {
    path: "/setup",
    component: _2a6a92ec,
    name: "setup"
  }, {
    path: "/admin/access-organisations",
    component: _38edca19,
    name: "admin-access-organisations"
  }, {
    path: "/admin/content",
    component: _5179f674,
    name: "admin-content"
  }, {
    path: "/admin/farms",
    component: _09871eca,
    name: "admin-farms",
    children: [{
      path: "api-errors",
      component: _473bd493,
      name: "admin-farms-index-api-errors"
    }, {
      path: "list",
      component: _f40caf1a,
      name: "admin-farms-index-list"
    }, {
      path: "map",
      component: _0a408a17,
      name: "admin-farms-index-map"
    }]
  }, {
    path: "/admin/lab-results",
    component: _d823fa7a,
    name: "admin-lab-results",
    children: [{
      path: "import",
      component: _61b9d6a1,
      name: "admin-lab-results-index-import"
    }, {
      path: "imported",
      component: _375b9d80,
      name: "admin-lab-results-index-imported"
    }]
  }, {
    path: "/admin/map",
    component: _0b87fee9,
    name: "admin-map"
  }, {
    path: "/admin/organisations",
    component: _65f9d5a6,
    name: "admin-organisations"
  }, {
    path: "/admin/settings",
    component: _a6b19568,
    name: "admin-settings",
    children: [{
      path: "profile",
      component: _dd9faa34,
      name: "admin-settings-index-profile"
    }, {
      path: "security",
      component: _7eaf0353,
      name: "admin-settings-index-security"
    }]
  }, {
    path: "/admin/team",
    component: _6800d206,
    name: "admin-team"
  }, {
    path: "/admin/users",
    component: _38cb6c15,
    name: "admin-users"
  }, {
    path: "/auth/forgot-password",
    component: _461fcb78,
    name: "auth-forgot-password"
  }, {
    path: "/auth/login",
    component: _14293a68,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _83e1c9ca,
    name: "auth-logout"
  }, {
    path: "/auth/native-callback",
    component: _f6d64b0c,
    name: "auth-native-callback"
  }, {
    path: "/auth/organisations",
    component: _0eb956d8,
    name: "auth-organisations"
  }, {
    path: "/auth/register",
    component: _ef96f612,
    name: "auth-register"
  }, {
    path: "/platform/action-plan",
    component: _625a0926,
    name: "platform-action-plan",
    children: [{
      path: "action-items",
      component: _e0d4ad84,
      name: "platform-action-plan-index-action-items"
    }, {
      path: "recommendation",
      component: _7e9eb50e,
      name: "platform-action-plan-index-recommendation"
    }]
  }, {
    path: "/platform/data",
    component: _c93feada,
    name: "platform-data"
  }, {
    path: "/platform/evidence",
    component: _8989a400,
    name: "platform-evidence"
  }, {
    path: "/platform/funding",
    component: _5d93f26c,
    name: "platform-funding"
  }, {
    path: "/platform/learning-hub",
    component: _0ac4b7af,
    name: "platform-learning-hub"
  }, {
    path: "/platform/map",
    component: _448ff12e,
    name: "platform-map"
  }, {
    path: "/platform/report",
    component: _d0069fc6,
    name: "platform-report"
  }, {
    path: "/platform/settings",
    component: _39a87ccc,
    name: "platform-settings",
    children: [{
      path: "business",
      component: _2da03453,
      name: "platform-settings-index-business"
    }, {
      path: "profile",
      component: _ab9c1b34,
      name: "platform-settings-index-profile"
    }, {
      path: "security",
      component: _f433a85a,
      name: "platform-settings-index-security"
    }]
  }, {
    path: "/platform/sharing",
    component: _3f6aa9e9,
    name: "platform-sharing"
  }, {
    path: "/setup/farm",
    component: _7a82e7ae,
    name: "setup-farm"
  }, {
    path: "/setup/welcome",
    component: _82f2b7c6,
    name: "setup-welcome",
    children: [{
      path: "certifications",
      component: _710ff75b,
      name: "setup-welcome-index-certifications"
    }, {
      path: "complete",
      component: _64b875ca,
      name: "setup-welcome-index-complete"
    }, {
      path: "farm-enterprises",
      component: _767a6afd,
      name: "setup-welcome-index-farm-enterprises"
    }, {
      path: "goals",
      component: _7e2df4ce,
      name: "setup-welcome-index-goals"
    }, {
      path: "relationships",
      component: _14ecd6c9,
      name: "setup-welcome-index-relationships"
    }, {
      path: "services",
      component: _06d4ec80,
      name: "setup-welcome-index-services"
    }]
  }, {
    path: "/admin/content/articles",
    component: _3196d63c,
    name: "admin-content-articles"
  }, {
    path: "/admin/content/funding",
    component: _3aae9834,
    name: "admin-content-funding"
  }, {
    path: "/admin/content/quick-wins",
    component: _d8617b14,
    name: "admin-content-quick-wins"
  }, {
    path: "/admin/organisations/clone",
    component: _70d14cb1,
    name: "admin-organisations-clone"
  }, {
    path: "/partner/farms/new",
    component: _ece7a45e,
    name: "partner-farms-new"
  }, {
    path: "/platform/data/carbon",
    component: _d9d36308,
    name: "platform-data-carbon"
  }, {
    path: "/platform/data/food-production",
    component: _31d3c109,
    name: "platform-data-food-production"
  }, {
    path: "/platform/data/sampling",
    component: _06350f8e,
    name: "platform-data-sampling",
    children: [{
      path: "overview",
      component: _21576cbf,
      name: "platform-data-sampling-index-overview"
    }, {
      path: "soil-lab",
      component: _7ce9df85,
      name: "platform-data-sampling-index-soil-lab"
    }, {
      path: "water",
      component: _314dbc7e,
      name: "platform-data-sampling-index-water"
    }, {
      path: ":parcelId",
      component: _6f7722d0,
      name: "platform-data-sampling-index-parcelId"
    }, {
      path: ":parcelId/birds",
      component: _ccc2a54a,
      name: "platform-data-sampling-index-parcelId-birds"
    }, {
      path: ":parcelId/hedgerows",
      component: _50508802,
      name: "platform-data-sampling-index-parcelId-hedgerows"
    }, {
      path: ":parcelId/plants",
      component: _630a195d,
      name: "platform-data-sampling-index-parcelId-plants"
    }, {
      path: ":parcelId/birds/:planItemId?",
      component: _49ea6102,
      name: "platform-data-sampling-index-parcelId-birds-planItemId"
    }, {
      path: ":parcelId/hedgerows/:planItemId?",
      component: _453f5323,
      name: "platform-data-sampling-index-parcelId-hedgerows-planItemId"
    }, {
      path: ":parcelId/plants/:planItemId?",
      component: _3301b201,
      name: "platform-data-sampling-index-parcelId-plants-planItemId"
    }, {
      path: ":parcelId/soils/:planItemId",
      component: _7b4b4c3c,
      name: "platform-data-sampling-index-parcelId-soils-planItemId"
    }, {
      path: ":parcelId/soils/:planItemId?/earthworms",
      component: _1469b2bc,
      name: "platform-data-sampling-index-parcelId-soils-planItemId-earthworms"
    }, {
      path: ":parcelId/soils/:planItemId?/ph",
      component: _7c3b7bfe,
      name: "platform-data-sampling-index-parcelId-soils-planItemId-ph"
    }, {
      path: ":parcelId/soils/:planItemId?/vess",
      component: _bd561756,
      name: "platform-data-sampling-index-parcelId-soils-planItemId-vess"
    }]
  }, {
    path: "/platform/data/soil-cover",
    component: _0a96a078,
    name: "platform-data-soil-cover"
  }, {
    path: "/platform/data/surveys",
    component: _cc900146,
    name: "platform-data-surveys"
  }, {
    path: "/platform/data/water",
    component: _82840750,
    name: "platform-data-water"
  }, {
    path: "/platform/evidence/collection",
    component: _01e762e3,
    name: "platform-evidence-collection"
  }, {
    path: "/platform/funding/funding-tool",
    component: _64cd5b23,
    name: "platform-funding-funding-tool",
    children: [{
      path: "practices",
      component: _7a12082c,
      name: "platform-funding-funding-tool-index-practices"
    }, {
      path: "schemes",
      component: _2431d5fc,
      name: "platform-funding-funding-tool-index-schemes"
    }]
  }, {
    path: "/platform/funding/natural-capital",
    component: _3730a0bc,
    name: "platform-funding-natural-capital"
  }, {
    path: "/platform/funding/price-premiums",
    component: _083d6b38,
    name: "platform-funding-price-premiums"
  }, {
    path: "/platform/funding/subsidies",
    component: _631eeffe,
    name: "platform-funding-subsidies"
  }, {
    path: "/platform/learning-hub/advice",
    component: _3d4df22a,
    name: "platform-learning-hub-advice"
  }, {
    path: "/platform/learning-hub/case-studies",
    component: _6283ff3d,
    name: "platform-learning-hub-case-studies"
  }, {
    path: "/platform/learning-hub/tutorials",
    component: _f47c289c,
    name: "platform-learning-hub-tutorials"
  }, {
    path: "/platform/report/archive",
    component: _8d7f6166,
    name: "platform-report-archive"
  }, {
    path: "/platform/report/v3",
    component: _96d52276,
    name: "platform-report-v3"
  }, {
    path: "/admin/content/articles/new",
    component: _b059e6ec,
    name: "admin-content-articles-new"
  }, {
    path: "/admin/content/funding/bodies",
    component: _cab7985e,
    name: "admin-content-funding-bodies"
  }, {
    path: "/admin/content/funding/land-types",
    component: _c31bff30,
    name: "admin-content-funding-land-types"
  }, {
    path: "/admin/content/funding/old",
    component: _0a9dd14c,
    name: "admin-content-funding-old"
  }, {
    path: "/admin/content/funding/practices",
    component: _f78b1e06,
    name: "admin-content-funding-practices"
  }, {
    path: "/admin/content/funding/programmes",
    component: _5af2b728,
    name: "admin-content-funding-programmes"
  }, {
    path: "/admin/content/funding/schemes",
    component: _76dc80d3,
    name: "admin-content-funding-schemes"
  }, {
    path: "/admin/content/quick-wins/new",
    component: _1fcd3f44,
    name: "admin-content-quick-wins-new"
  }, {
    path: "/platform/data/surveys/animal-welfare",
    component: _dcc84980,
    name: "platform-data-surveys-animal-welfare"
  }, {
    path: "/platform/data/surveys/biodiversity",
    component: _3d5929d4,
    name: "platform-data-surveys-biodiversity"
  }, {
    path: "/platform/data/surveys/farm-practices",
    component: _b873f774,
    name: "platform-data-surveys-farm-practices"
  }, {
    path: "/platform/data/surveys/social",
    component: _042c0cf2,
    name: "platform-data-surveys-social"
  }, {
    path: "/platform/data/surveys/water",
    component: _85d41dbc,
    name: "platform-data-surveys-water"
  }, {
    path: "/platform/evidence/sfi/management",
    component: _3a5b9941,
    name: "platform-evidence-sfi-management"
  }, {
    path: "/platform/report/v2/animal-welfare",
    component: _0386723c,
    name: "platform-report-v2-animal-welfare"
  }, {
    path: "/platform/report/v2/biodiversity",
    component: _563413d2,
    name: "platform-report-v2-biodiversity"
  }, {
    path: "/platform/report/v2/carbon",
    component: _5fdff524,
    name: "platform-report-v2-carbon"
  }, {
    path: "/platform/report/v2/social",
    component: _f178c124,
    name: "platform-report-v2-social"
  }, {
    path: "/platform/report/v2/soils",
    component: _1e076fee,
    name: "platform-report-v2-soils"
  }, {
    path: "/platform/report/v2/water",
    component: _7329f98c,
    name: "platform-report-v2-water"
  }, {
    path: "/platform/report/v3/animal-welfare",
    component: _055fc55b,
    name: "platform-report-v3-animal-welfare"
  }, {
    path: "/platform/report/v3/biodiversity",
    component: _5235a2b1,
    name: "platform-report-v3-biodiversity"
  }, {
    path: "/platform/report/v3/carbon",
    component: _d6bc30e6,
    name: "platform-report-v3-carbon"
  }, {
    path: "/platform/report/v3/social",
    component: _4bd5818d,
    name: "platform-report-v3-social"
  }, {
    path: "/platform/report/v3/soils",
    component: _41a6260a,
    name: "platform-report-v3-soils"
  }, {
    path: "/platform/report/v3/water",
    component: _785850e6,
    name: "platform-report-v3-water"
  }, {
    path: "/admin/content/funding/bodies/new",
    component: _ab566942,
    name: "admin-content-funding-bodies-new"
  }, {
    path: "/admin/content/funding/land-types/new",
    component: _238ad1b6,
    name: "admin-content-funding-land-types-new"
  }, {
    path: "/admin/content/funding/old/new",
    component: _417d8b9a,
    name: "admin-content-funding-old-new"
  }, {
    path: "/admin/content/funding/practices/new",
    component: _a71f38ea,
    name: "admin-content-funding-practices-new"
  }, {
    path: "/admin/content/funding/programmes/new",
    component: _db6b8f14,
    name: "admin-content-funding-programmes-new"
  }, {
    path: "/admin/content/funding/schemes/new",
    component: _7ae070e1,
    name: "admin-content-funding-schemes-new"
  }, {
    path: "/platform/report/v2/animal-welfare/antibiotics",
    component: _79f3f475,
    name: "platform-report-v2-animal-welfare-antibiotics"
  }, {
    path: "/platform/report/v2/animal-welfare/outcomes",
    component: _50ae7732,
    name: "platform-report-v2-animal-welfare-outcomes"
  }, {
    path: "/platform/report/v2/biodiversity/birds",
    component: _238b19da,
    name: "platform-report-v2-biodiversity-birds"
  }, {
    path: "/platform/report/v2/biodiversity/hedgerows",
    component: _1108a27e,
    name: "platform-report-v2-biodiversity-hedgerows"
  }, {
    path: "/platform/report/v2/biodiversity/landcover",
    component: _d88a92a8,
    name: "platform-report-v2-biodiversity-landcover"
  }, {
    path: "/platform/report/v2/biodiversity/plants",
    component: _d1d724d0,
    name: "platform-report-v2-biodiversity-plants"
  }, {
    path: "/platform/report/v2/biodiversity/practices",
    component: _6e2f6ad0,
    name: "platform-report-v2-biodiversity-practices"
  }, {
    path: "/platform/report/v2/biodiversity/woodland-connectivity",
    component: _83c26d68,
    name: "platform-report-v2-biodiversity-woodland-connectivity"
  }, {
    path: "/platform/report/v2/carbon/balance",
    component: _45aaf510,
    name: "platform-report-v2-carbon-balance"
  }, {
    path: "/platform/report/v2/carbon/emissions",
    component: _b501ad30,
    name: "platform-report-v2-carbon-emissions"
  }, {
    path: "/platform/report/v2/carbon/stored-in-soils",
    component: _4a3c0c9c,
    name: "platform-report-v2-carbon-stored-in-soils"
  }, {
    path: "/platform/report/v2/carbon/stored-in-vegetation",
    component: _5d9ae320,
    name: "platform-report-v2-carbon-stored-in-vegetation"
  }, {
    path: "/platform/report/v2/social/land-access",
    component: _2e4c6dbc,
    name: "platform-report-v2-social-land-access"
  }, {
    path: "/platform/report/v2/soils/bulk-density",
    component: _34f404c6,
    name: "platform-report-v2-soils-bulk-density"
  }, {
    path: "/platform/report/v2/soils/earthworms",
    component: _5aef0f0f,
    name: "platform-report-v2-soils-earthworms"
  }, {
    path: "/platform/report/v2/soils/ph",
    component: _a140495e,
    name: "platform-report-v2-soils-ph"
  }, {
    path: "/platform/report/v2/soils/som",
    component: _2ad17df0,
    name: "platform-report-v2-soils-som"
  }, {
    path: "/platform/report/v2/soils/vess",
    component: _c6426a30,
    name: "platform-report-v2-soils-vess"
  }, {
    path: "/platform/report/v2/water/nitrogen-balance",
    component: _65261182,
    name: "platform-report-v2-water-nitrogen-balance"
  }, {
    path: "/platform/report/v2/water/surface-runoff-avoidance",
    component: _4181fede,
    name: "platform-report-v2-water-surface-runoff-avoidance"
  }, {
    path: "/platform/report/v2/water/usage",
    component: _5d1f624a,
    name: "platform-report-v2-water-usage"
  }, {
    path: "/platform/report/v2/water/water-samples",
    component: _1784478d,
    name: "platform-report-v2-water-water-samples"
  }, {
    path: "/platform/report/v3/animal-welfare/antibiotics",
    component: _02243454,
    name: "platform-report-v3-animal-welfare-antibiotics"
  }, {
    path: "/platform/report/v3/animal-welfare/welfare-beef",
    component: _ac1ce668,
    name: "platform-report-v3-animal-welfare-welfare-beef"
  }, {
    path: "/platform/report/v3/animal-welfare/welfare-dairy",
    component: _095779db,
    name: "platform-report-v3-animal-welfare-welfare-dairy"
  }, {
    path: "/platform/report/v3/animal-welfare/welfare-pigs",
    component: _2ffbd0cd,
    name: "platform-report-v3-animal-welfare-welfare-pigs"
  }, {
    path: "/platform/report/v3/animal-welfare/welfare-poultry",
    component: _579fd72d,
    name: "platform-report-v3-animal-welfare-welfare-poultry"
  }, {
    path: "/platform/report/v3/animal-welfare/welfare-sheep",
    component: _b389173a,
    name: "platform-report-v3-animal-welfare-welfare-sheep"
  }, {
    path: "/platform/report/v3/biodiversity/bird-species-abundance",
    component: _2aea6545,
    name: "platform-report-v3-biodiversity-bird-species-abundance"
  }, {
    path: "/platform/report/v3/biodiversity/connectivity",
    component: _196b5aa8,
    name: "platform-report-v3-biodiversity-connectivity"
  }, {
    path: "/platform/report/v3/biodiversity/crop-and-livestock-diversity",
    component: _314d68b4,
    name: "platform-report-v3-biodiversity-crop-and-livestock-diversity"
  }, {
    path: "/platform/report/v3/biodiversity/flora",
    component: _20756b37,
    name: "platform-report-v3-biodiversity-flora"
  }, {
    path: "/platform/report/v3/biodiversity/habitat-management",
    component: _2fac865a,
    name: "platform-report-v3-biodiversity-habitat-management"
  }, {
    path: "/platform/report/v3/biodiversity/hedgerow-structure",
    component: _87199928,
    name: "platform-report-v3-biodiversity-hedgerow-structure"
  }, {
    path: "/platform/report/v3/biodiversity/space-for-nature",
    component: _1049e843,
    name: "platform-report-v3-biodiversity-space-for-nature"
  }, {
    path: "/platform/report/v3/carbon/balance",
    component: _76674852,
    name: "platform-report-v3-carbon-balance"
  }, {
    path: "/platform/report/v3/carbon/emissions",
    component: _2c04e407,
    name: "platform-report-v3-carbon-emissions"
  }, {
    path: "/platform/report/v3/carbon/hedgerow-flows",
    component: _840dc5bc,
    name: "platform-report-v3-carbon-hedgerow-flows"
  }, {
    path: "/platform/report/v3/carbon/hedgerow-stocks",
    component: _41de0e60,
    name: "platform-report-v3-carbon-hedgerow-stocks"
  }, {
    path: "/platform/report/v3/carbon/outside-woodland-flows",
    component: _7a19f60d,
    name: "platform-report-v3-carbon-outside-woodland-flows"
  }, {
    path: "/platform/report/v3/carbon/outside-woodland-stocks",
    component: _b1e98576,
    name: "platform-report-v3-carbon-outside-woodland-stocks"
  }, {
    path: "/platform/report/v3/carbon/soil-flows",
    component: _0f456be8,
    name: "platform-report-v3-carbon-soil-flows"
  }, {
    path: "/platform/report/v3/carbon/soil-stocks",
    component: _1d9b2db4,
    name: "platform-report-v3-carbon-soil-stocks"
  }, {
    path: "/platform/report/v3/carbon/woodland-flows",
    component: _4e8c7de5,
    name: "platform-report-v3-carbon-woodland-flows"
  }, {
    path: "/platform/report/v3/carbon/woodland-stocks",
    component: _60e9b06d,
    name: "platform-report-v3-carbon-woodland-stocks"
  }, {
    path: "/platform/report/v3/social/community",
    component: _552ddba4,
    name: "platform-report-v3-social-community"
  }, {
    path: "/platform/report/v3/social/food-production-energy",
    component: _5acce9c2,
    name: "platform-report-v3-social-food-production-energy"
  }, {
    path: "/platform/report/v3/social/land-access",
    component: _36494ffe,
    name: "platform-report-v3-social-land-access"
  }, {
    path: "/platform/report/v3/soils/bulk-density",
    component: _30f593a5,
    name: "platform-report-v3-soils-bulk-density"
  }, {
    path: "/platform/report/v3/soils/cn-ratio",
    component: _e2e8fabe,
    name: "platform-report-v3-soils-cn-ratio"
  }, {
    path: "/platform/report/v3/soils/earthworms",
    component: _3d166ca4,
    name: "platform-report-v3-soils-earthworms"
  }, {
    path: "/platform/report/v3/soils/ph",
    component: _dd84b620,
    name: "platform-report-v3-soils-ph"
  }, {
    path: "/platform/report/v3/soils/soil-cover",
    component: _3dfa1761,
    name: "platform-report-v3-soils-soil-cover"
  }, {
    path: "/platform/report/v3/soils/som",
    component: _4472ab49,
    name: "platform-report-v3-soils-som"
  }, {
    path: "/platform/report/v3/soils/vess",
    component: _7e70a8c7,
    name: "platform-report-v3-soils-vess"
  }, {
    path: "/platform/report/v3/water/groundwater",
    component: _6011116b,
    name: "platform-report-v3-water-groundwater"
  }, {
    path: "/platform/report/v3/water/nitrogen-balance",
    component: _838402c4,
    name: "platform-report-v3-water-nitrogen-balance"
  }, {
    path: "/platform/report/v3/water/phosphate-balance",
    component: _19d26608,
    name: "platform-report-v3-water-phosphate-balance"
  }, {
    path: "/platform/report/v3/water/potash-balance",
    component: _599ff9e5,
    name: "platform-report-v3-water-potash-balance"
  }, {
    path: "/platform/report/v3/water/resource-availability",
    component: _03f7d175,
    name: "platform-report-v3-water-resource-availability"
  }, {
    path: "/platform/report/v3/water/runoff-management",
    component: _25f44be7,
    name: "platform-report-v3-water-runoff-management"
  }, {
    path: "/platform/report/v3/water/storage",
    component: _246d9014,
    name: "platform-report-v3-water-storage"
  }, {
    path: "/platform/report/v3/water/water-usage",
    component: _3e35fd66,
    name: "platform-report-v3-water-water-usage"
  }, {
    path: "/",
    component: _9f7385c8,
    name: "index"
  }, {
    path: "/admin/content/articles/view/:id?",
    component: _06599cd6,
    name: "admin-content-articles-view-id"
  }, {
    path: "/admin/content/funding/bodies/:id",
    component: _d2c2130e,
    name: "admin-content-funding-bodies-id"
  }, {
    path: "/admin/content/funding/land-types/:id?",
    component: _0fd4fcd0,
    name: "admin-content-funding-land-types-id"
  }, {
    path: "/admin/content/funding/old/:id",
    component: _2dc7b6b4,
    name: "admin-content-funding-old-id"
  }, {
    path: "/admin/content/funding/practices/:id",
    component: _ce8ae2b6,
    name: "admin-content-funding-practices-id"
  }, {
    path: "/admin/content/funding/programmes/:id",
    component: _7e946390,
    name: "admin-content-funding-programmes-id"
  }, {
    path: "/admin/content/funding/schemes/:id",
    component: _672a9bfb,
    name: "admin-content-funding-schemes-id"
  }, {
    path: "/platform/funding/funding-tool/practices/:id",
    component: _0cdaf294,
    name: "platform-funding-funding-tool-practices-id"
  }, {
    path: "/admin/content/articles/:id",
    component: _d7c590b8,
    name: "admin-content-articles-id"
  }, {
    path: "/admin/content/quick-wins/:id?",
    component: _0c176a5e,
    name: "admin-content-quick-wins-id"
  }, {
    path: "/admin/access-organisations/:id?",
    component: _852d027e,
    name: "admin-access-organisations-id"
  }, {
    path: "/admin/organisations/:id",
    component: _65a5ece4,
    name: "admin-organisations-id",
    children: [{
      path: "activity",
      component: _214fd5a0,
      name: "admin-organisations-id-activity"
    }, {
      path: "overview",
      component: _66658b0a,
      name: "admin-organisations-id-overview"
    }, {
      path: "users",
      component: _406d87c7,
      name: "admin-organisations-id-users"
    }]
  }, {
    path: "/admin/users/:id",
    component: _6a012086,
    name: "admin-users-id",
    children: [{
      path: "activity",
      component: _a768175e,
      name: "admin-users-id-activity"
    }, {
      path: "notifications",
      component: _49216f96,
      name: "admin-users-id-notifications"
    }, {
      path: "overview",
      component: _1d3cac8a,
      name: "admin-users-id-overview"
    }]
  }, {
    path: "/auth/invited/:verificationCode?",
    component: _06d3bef2,
    name: "auth-invited-verificationCode"
  }, {
    path: "/auth/reset-password/:verificationCode",
    component: _ee8550b6,
    name: "auth-reset-password-verificationCode"
  }, {
    path: "/platform/learning-hub/:slug?/:id",
    component: _bf024edc,
    name: "platform-learning-hub-slug-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
